import React from "react";

const FeatureStatusContext = React.createContext({
  isTradeEnabled: false,
  isAuctionEnabled: false,
  isParamEnabled: false,
  isRedsysEnabled: false,
});

export default FeatureStatusContext;
