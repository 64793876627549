import React from "react";

export default function ReviewItem({ review }) {
  return (
    <div className="review-item">
      <p className="service-details">"{review.text}"</p>
      <h2>{review.user}</h2>
      <p style={{ fontStyle: "italic" }}>{review.date}</p>
    </div>
  );
}
