import React from "react";
import { FaDiscord, FaFacebook, FaTiktok, FaTwitter } from "react-icons/fa";
import {
  discordLink,
  facebookLink,
  tiktokLink,
  twitterLink,
} from "../../constants";
import useWindowDimensions from "../../hooks/useWIndowDimensions";
import HomePageSearch from "./HomePageSearch";

export default function HomepageBanner() {
  const { width } = useWindowDimensions();

  const isSmallScreen = width < 900;
  return (
    <div className="homepage-banner">
      <div className="socials-overlay">
        <a href={discordLink} target="_blank" rel="noreferrer">
          <FaDiscord className="link" />
        </a>
        <a href={twitterLink} target="_blank" rel="noreferrer">
          <FaTwitter className="link" />
        </a>
        <a href={facebookLink} target="_blank" rel="noreferrer">
          <FaFacebook className="link" />
        </a>
        <a href={tiktokLink} target="_blank" rel="noreferrer">
          <FaTiktok className="link" />
        </a>
      </div>
      <div className="homepage-search">
        <img
          className="homepage-art"
          src="/static/images/smurfskins-art.png"
          alt="banner"
        />
        <HomePageSearch size={isSmallScreen ? "small" : "huge"} />
      </div>
    </div>
  );
}
