import { useContext, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Button, Icon, Label, Popup, Table } from "semantic-ui-react";
import DarkModeContext from "../../contexts/DarkModeContext";
import { getRankAndDivision } from "../../utils";
import ShowSkinsPopup from "../ShowSkinsPopup";

const divisionValueMappign = {
  I: 1,
  II: 2,
  III: 3,
  IV: 4,
};

const renderTags = (product) => {
  let tags = [];
  if (product.isPremium) {
    tags.push({
      label: "Premium",
      icon: "star",
      description: "This account has significantly lower banrate.",
    });
  }

  if (product.isOldStock) {
    tags.push({
      label: "Old Stock",
      icon: "info circle",
      description:
        "This account was leveled up before august 2022 and has significantly lower banrate and lifetime warranty.",
    });
  }

  // Show safe tag for non-premium safe fresh stock only
  if (!product.isPremium && !product.isOldStock && product.isSafe) {
    tags.push({
      label: "Safe",
      icon: "shield",
      description: "This account has very low banrate and lifetime warranty.",
    });
  }

  if (!product.hasWarranty) {
    tags.push({
      label: "No Warranty",
      icon: "warning sign",
      description:
        "This account has no warranty and has a high chance of getting banned.",
    });
  }
  if (product.isHandleveled) {
    tags.push({
      label: "Handleveled",
      icon: "handshake outline",
      color: "handleveled",
    });
  } else {
    if (!product.rank || ["0", "UN"].includes(product.rank.slice(0, 2))) {
      tags.push({
        label: "Unranked",
        icon: "shield",
        color: "black",
      });
    } else {
      let fullRank = getRankAndDivision(
        product.rank.slice(0, 2),
        divisionValueMappign[product.division],
      );
      tags.push({
        label: fullRank[0],
        icon: "shield",
        color: fullRank[1],
      });
    }
  }
  if (product.flashKey) {
    tags.push({
      label: `Flash on ${product.flashKey.toUpperCase()}`,
      color: "handleveled",
    });
  }

  return tags.map((tag, index) => {
    if (tag.description) {
      return (
        <Popup
          key={index}
          trigger={
            <Label key={index} className={tag.color}>
              {tag.icon && <Icon name={tag.icon} />}
              {tag.label}
            </Label>
          }
          content={tag.description}
        />
      );
    }
    return (
      <Label key={index}>
        {tag.icon && <Icon name={tag.icon} />}
        {tag.label}
      </Label>
    );
  });
};

export const AllPurchasesTableItem = ({
  product,
  setIsModalOpen,
  setIsAuctionModalOpen,
  setIsHideModalOpen,
  setSelectedProduct,
  isTradeEnabled,
  isAuctionEnabled,
}) => {
  const [view, setView] = useState(false);
  const { isDarkMode } = useContext(DarkModeContext);
  const isHiddenAccounts = useRouteMatch(
    "/my-account/profile/your-purchases/hidden-accounts/",
  );
  const isAuctionAllowed = () => {
    return (
      isAuctionEnabled &&
      (product.isPremium ||
        product.isOldStock ||
        product.isSafe ||
        product.isHandleveled)
    );
  };

  return (
    <Table.Row>
      <Table.Cell>
        {new Date(product.date).toLocaleDateString("en-US", {
          hour: "numeric",
          minute: "numeric",
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </Table.Cell>
      <Table.Cell>{product.username}</Table.Cell>
      <Table.Cell>
        <div className="table-password">
          <Popup
            disabled={!view}
            trigger={
              <span
                className="password-text"
                style={{
                  marginTop: view ? "0" : "6.5px",
                }}
              >
                {view ? product.password : "************"}
              </span>
            }
            size="small"
            flowing
            hideOnScroll={true}
            inverted={isDarkMode}
            hoverable
          >
            {product.password}
          </Popup>
          <div className="manage-password-btns">
            <Popup
              content="Show/Hide Password"
              size="mini"
              inverted={isDarkMode}
              hideOnScroll={true}
              trigger={
                <Icon
                  link
                  name={view ? "eye slash" : "eye"}
                  onClick={() => setView(!view)}
                />
              }
            />
            <Popup
              content="Copy Password"
              size="mini"
              inverted={isDarkMode}
              hideOnScroll={true}
              trigger={
                <Icon
                  link
                  name="copy"
                  onClick={() =>
                    navigator.clipboard.writeText(product.password)
                  }
                />
              }
            />
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>{product.region}</Table.Cell>
      <Table.Cell>{product.level}+</Table.Cell>
      <Table.Cell>{product.blueEssence.toLocaleString()}+</Table.Cell>
      <Table.Cell>{product.orangeEssence.toLocaleString()}</Table.Cell>
      <Table.Cell>
        {product.soloWins || " - "}/{product.soloLosses || " - "}
      </Table.Cell>
      <Table.Cell>
        <ShowSkinsPopup
          skins={product.skins}
          permanent_skins={product.permanentSkins}
          owned_skins={product.ownedSkins}
          button={<Label as={Button}>View</Label>}
        />
      </Table.Cell>
      <Table.Cell>{renderTags(product)}</Table.Cell>
      <Table.Cell>
        {isTradeEnabled && (
          <Label
            as={Button}
            primary
            onClick={() => {
              setIsModalOpen(true);
              setSelectedProduct(product);
            }}
          >
            Trade
          </Label>
        )}
        {isAuctionAllowed() && (
          <Label
            as={Button}
            primary
            onClick={() => {
              setIsAuctionModalOpen(true);
              setSelectedProduct(product);
            }}
          >
            Auction
          </Label>
        )}
        <Label
          as={Button}
          primary
          onClick={() => {
            setIsHideModalOpen(true);
            setSelectedProduct(product);
          }}
        >
          {isHiddenAccounts ? "Unhide" : "Hide"}
        </Label>
      </Table.Cell>
    </Table.Row>
  );
};
