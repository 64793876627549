import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router";
import { useStripe } from "@stripe/react-stripe-js";
import Cookies from "js-cookie";
import { Button, Header, Icon, Message, Segment } from "semantic-ui-react";
import DarkModeContext from "../../contexts/DarkModeContext";
import ValidateDiscountCoupon from "./ValidateDiscountCoupon";
import { getRegion } from "../../utils";
import { ParamCheckoutModal } from "./ParamCheckoutModal";
import PriceContext from "../../contexts/PricesContext";
import FeatureStatusContext from "../../contexts/FeatureStatusContext";
import {
  minimumCreditCardCheckout,
  minimumStripeCheckout,
  rankedValues,
} from "../../constants";

const PAYMENT_GATEWAYS = {
  STRIPE: "Stripe",
  PARAM: "Param",
  REDSYS: "Redsys",
};

const getRoundedUp = (n) => {
  return Math.round(n * 100) / 100;
};

export default function Checkout({ cartItems, setErrorItems }) {
  const history = useHistory();

  const { prices } = useContext(PriceContext);

  const [total, setTotal] = useState(0);
  const [stripeCharge, setStripeCharge] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [balancePayment, setBalancePayment] = useState(0);
  const [cardPayment, setCardPayment] = useState(0);
  const { isParamEnabled, isRedsysEnabled } = useContext(FeatureStatusContext);
  const [paymentGateway, setPaymentGateway] = useState(
    isRedsysEnabled ? PAYMENT_GATEWAYS.REDSYS : PAYMENT_GATEWAYS.STRIPE,
  );
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [discountData, setDiscountData] = useState();
  const [discount, setDiscount] = useState(0);
  const [error, setError] = useState();
  const [paramModalOpen, setParamModalOpen] = React.useState(false);
  const [paramAmount, setParamAmount] = useState(0);
  const [paramDescription, setParamDescription] = useState("");

  const { isDarkMode } = useContext(DarkModeContext);
  const stripe = useStripe();

  const fetchUserBalance = useCallback(
    async (signal) => {
      setLoading(true);
      try {
        setLoading(true);
        const response = await fetch("/api/user/balance/", { signal });
        if (response.ok) {
          const json = await response.json();
          setBalance(parseFloat(json.balance));
          return;
        }
        if (response.status === 429) {
          history.push("/429/");
        }
      } catch (reason) {
        if (reason.name === "AbortError") return;
        console.log(reason);
      } finally {
        setLoading(false);
      }
    },
    [history],
  );

  const checkout = async () => {
    try {
      setLoading(true);
      setError(null);
      setErrorItems([]);
      const response = await fetch("/api/create-checkout-session/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({
          cartItems: Object.values(cartItems).map((cartitem) => cartitem.id),
          ...(discountData && { discountCode: discountData.code }),
          totalPrice: total,
          paymentGateway: paymentGateway.toLowerCase(),
        }),
      });
      if (response.status === 403) {
        return;
      }
      if (response.status === 400) {
        const json = await response.json();
        if (json.detail === "PRODUCT_UNAVAILABLE") {
          setError("Items highlighted in red are not available anymore.");
          setErrorItems(json.cartItems);
          setLoading(false);
        } else if (json.detail) {
          setError(json.detail);
          setLoading(false);
        } else if (json.cartItems) {
          setError("Cart Items: " + json.cartItems[0]);
        } else if (json.discountCode) {
          setError("Discount Code: " + json.discountCode[0]);
        } else if (json.totalPrice) {
          setError("Total Price: " + json.totalPrice[0]);
        } else if (json.paymentGateway) {
          setError("Payment Gateway: " + json.paymentGateway[0]);
        } else {
          setError("Something went wrong.");
        }
        return;
      }
      if (response.status === 429) {
        history.push("/429/");
      }
      if (!response.ok) {
        setError("Something went wrong.");
        setLoading(false);
        return;
      }
      if (response.status === 201) {
        history.push("/my-cart/purchase-completed/");
        return;
      }
      // Param
      if (
        response.status === 200 &&
        paymentGateway === PAYMENT_GATEWAYS.PARAM
      ) {
        setParamModalOpen(true);
        const json = await response.json();
        const amount = json.paramAmount;
        const description = json.description;
        setParamAmount(amount);
        setParamDescription(description);
        return;
      }
      // Redsys
      if (
        response.status === 200 &&
        paymentGateway === PAYMENT_GATEWAYS.REDSYS
      ) {
        const json = await response.json();
        // Create a form element
        const form = document.createElement("form");
        form.method = "POST";
        form.action = json.redsysUrl;
        form.target = "_blank";

        // Add form fields
        Object.keys(json.redsysPayload).forEach((key) => {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = json.redsysPayload[key];
          form.appendChild(input);
        });

        // Append form to the body and submit it
        document.body.appendChild(form);
        form.submit();

        // Remove form after submission
        document.body.removeChild(form);
        return;
      }
      const json = await response.json();
      const error = await stripe.redirectToCheckout({ sessionId: json.id });
      console.log(error);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const cartItemsCount = useMemo(() => {
    return Object.entries(cartItems).reduce((acc, cur) => {
      if (acc[`${cur[1].region}Level${cur[1].discreteLevel}`]) {
        acc[`${cur[1].region}Level${cur[1].discreteLevel}`].count++;
      } else {
        acc[`${cur[1].region}Level${cur[1].discreteLevel}`] = {
          count: 1,
          region: cur[1].region,
          discreteLevel: cur[1].discreteLevel,
        };
      }
      return acc;
    }, {});
  }, [cartItems]);

  useEffect(() => fetchUserBalance(), [fetchUserBalance]);

  useEffect(() => {
    if (paymentGateway === PAYMENT_GATEWAYS.STRIPE)
      setStripeCharge(prices.extraStripeCharge);
    else setStripeCharge(0);
  }, [paymentGateway, prices.extraStripeCharge]);

  useEffect(() => {
    const cartItemList = Object.values(cartItems);
    let tempSubTotal = 0;
    cartItemList.forEach((i) => (tempSubTotal += i.price));
    setSubTotal(tempSubTotal);

    let discountAmount = 0;
    if (discountData) {
      if (discountData.type === "PERCENTAGE") {
        cartItemList.forEach((i) => {
          if (!rankedValues.includes(i.rank) && !i.isHandleveled) {
            discountAmount +=
              i.price * (parseFloat(discountData?.amount) / 100);
          }
        });
      }
    }
    // discount is rounded UP
    discountAmount = getRoundedUp(discountAmount);
    setDiscount(discountAmount);

    let totalAmount = tempSubTotal - discountAmount + stripeCharge;
    if (totalAmount <= balance) {
      setBalancePayment(totalAmount);
      setCardPayment(0);
    } else if (paymentGateway === PAYMENT_GATEWAYS.PARAM) {
      let tempCardPayment = Math.max(
        minimumCreditCardCheckout,
        totalAmount - balance,
      );
      setCardPayment(tempCardPayment);
      setBalancePayment(totalAmount - tempCardPayment);
    } else {
      let tempCardPayment = Math.max(
        minimumStripeCheckout,
        totalAmount - balance,
      );
      setCardPayment(tempCardPayment);
      setBalancePayment(totalAmount - tempCardPayment);
    }
    setTotal(getRoundedUp(totalAmount));
  }, [cartItems, balance, discountData, stripeCharge, paymentGateway]);

  return (
    <>
      <div className="order-summary-new">
        <div className="checkout-header">
          <h2>Order Summary</h2>
          <p>
            Your Balance:{" "}
            {balance.toLocaleString(undefined, {
              style: "currency",
              currency: "EUR",
            })}
          </p>
          <Message info>
            <Icon name="info circle" />
            {isParamEnabled
              ? "Credit card or stripe checkout will be used if balance is not enough."
              : "Stripe checkout will be used if balance is not enough."}
          </Message>
        </div>

        <div className="item-summary-div">
          {Object.keys(cartItemsCount).length > 0 ? (
            Object.values(cartItemsCount).map((item, i) => {
              return (
                <div className="summary-item-each" key={i}>
                  <div className="inline-flex">
                    <span className="items-count">{item.count}x</span>
                    <p>
                      {getRegion(item.region)} Level {item.discreteLevel}+
                    </p>
                  </div>
                  <p>League of Legends Account</p>
                </div>
              );
            })
          ) : (
            <p className="no-items-selected">No items selected</p>
          )}
        </div>

        <ValidateDiscountCoupon
          cartItems={cartItems}
          loading={loading}
          setLoading={setLoading}
          onSuccess={(data) => {
            setDiscountData(data);
          }}
          onClear={() => setDiscountData(null)}
        />
        <div>
          {isParamEnabled && (
            <Message info>
              <Icon name="info circle" />
              Get 10% cashback as account balance on credit card checkout.
            </Message>
          )}
          {Object.keys(cartItems).length > 0 &&
            cardPayment > 0 &&
            isRedsysEnabled && (
              <div className="payment-gateway-selection">
                <Button
                  style={{
                    border: isDarkMode ? "1px solid rgb(46 77 93)" : "",
                  }}
                  className={`item ${isDarkMode ? "dark" : ""}`}
                  active={paymentGateway === PAYMENT_GATEWAYS.REDSYS}
                  onClick={() => setPaymentGateway(PAYMENT_GATEWAYS.REDSYS)}
                >
                  <Header size="small" inverted={isDarkMode}>
                    <Icon
                      name="credit card outline"
                      size="large"
                      inverted={isDarkMode}
                    />
                    Card
                  </Header>
                </Button>
                <Button
                  style={{
                    border: isDarkMode ? "1px solid rgb(46 77 93)" : "",
                  }}
                  className={`item ${isDarkMode ? "dark" : ""}`}
                  active={paymentGateway === PAYMENT_GATEWAYS.STRIPE}
                  onClick={() => setPaymentGateway(PAYMENT_GATEWAYS.STRIPE)}
                >
                  <Header size="small" inverted={isDarkMode}>
                    <Icon name="stripe s" size="large" inverted={isDarkMode} />{" "}
                    Stripe
                  </Header>
                </Button>
              </div>
            )}
        </div>
        <div className="summary-div">
          <div className="summary-item">
            <p>Sub Total</p>
            <b>
              {subTotal.toLocaleString(undefined, {
                style: "currency",
                currency: "EUR",
              })}
            </b>
          </div>
          {discountData && (
            <div className="summary-item">
              <p>{`Discount (${
                discountData.discountType === "FLAT"
                  ? discountData.amount.toLocaleString(undefined, {
                      style: "currency",
                      currency: "EUR",
                    })
                  : (discountData.amount / 100).toLocaleString(undefined, {
                      style: "percent",
                    })
              })`}</p>
              <b>
                {(discount * -1).toLocaleString(undefined, {
                  style: "currency",
                  currency: "EUR",
                })}
              </b>
            </div>
          )}
          {stripeCharge ? (
            <div className="summary-item">
              <p>Stripe Charge</p>
              <b>
                {stripeCharge.toLocaleString(undefined, {
                  style: "currency",
                  currency: "EUR",
                })}
              </b>
            </div>
          ) : null}
          <div className="summary-item">
            <p>Total</p>
            <b>
              {total.toLocaleString(undefined, {
                style: "currency",
                currency: "EUR",
              })}
            </b>
          </div>
          {Object.keys(cartItems).length > 0 && (
            <>
              <div className="summary-item">
                <p>Balance Payment</p>
                <b>
                  {balancePayment.toLocaleString(undefined, {
                    style: "currency",
                    currency: "EUR",
                  })}
                </b>
              </div>
              {cardPayment > 0 && (
                <div className="summary-item">
                  <p>
                    {paymentGateway === PAYMENT_GATEWAYS.STRIPE
                      ? "Stripe Payment"
                      : "Card Payment"}
                  </p>
                  <b>
                    {cardPayment.toLocaleString(undefined, {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </b>
                </div>
              )}
            </>
          )}
        </div>

        <Button
          loading={loading}
          disabled={loading || Object.keys(cartItems).length <= 0}
          color="teal"
          onClick={checkout}
        >
          {cardPayment > 0 ? (
            <div
              style={{
                display: "flex",
                margin: 0,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>
                {cardPayment.toLocaleString(undefined, {
                  style: "currency",
                  currency: "EUR",
                })}
              </span>

              <span>
                Checkout <Icon name="right arrow" />
              </span>
            </div>
          ) : (
            "Pay using balance"
          )}
        </Button>

        {error && (
          <Segment
            style={{
              margin: "0",
              padding: "0",
              borderRadius: "0",
              border: "none",
            }}
          >
            <Message error>
              <Icon name="warning circle" />
              {error}
            </Message>
          </Segment>
        )}
      </div>
      {isParamEnabled && paramModalOpen && (
        <ParamCheckoutModal
          open={paramModalOpen}
          setOpen={setParamModalOpen}
          paramAmount={paramAmount}
          totalAmount={total}
          cartItems={cartItems}
          discountCode={discountData && discountData.code}
          description={paramDescription}
        />
      )}
    </>
  );
}
