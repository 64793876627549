import React, { useContext } from "react";
import { Header, Icon } from "semantic-ui-react";
import { mythicAccountsLink } from "../constants";
import DarkModeContext from "../contexts/DarkModeContext";

export default function NoMythicSearchResults() {
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <div className="no-search-results">
      <Header inverted={isDarkMode} icon>
        <Icon name="search" />
        No mythic accounts found.
      </Header>
      <Header inverted={isDarkMode} icon>
        You can find prestige and mythic accounts at{" "}
        <a href={mythicAccountsLink} target="_blank" rel="noreferrer">
          Lolaccshop
        </a>
        .
      </Header>
    </div>
  );
}
