import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Icon,
  Label,
  LabelGroup,
  Popup,
  Segment,
} from "semantic-ui-react";
import InfoPopup from "../../components/InfoPopup";
import ShowSkinsPopup from "../../components/ShowSkinsPopup";
import { rankedValues } from "../../constants";
import DarkModeContext from "../../contexts/DarkModeContext";
import {
  getRegion,
  calculateDaysDifference,
  calculateTimeRemaining,
} from "../../utils";
import { AuctionItemStatusModal } from "../../components/Modal/AuctionItemStatusModal";

export default function AuctionCard({
  product,
  isActive,
  setSelectedProduct,
  setIsCancel,
  setIsEdit,
  isTradeCard = false,
}) {
  const { isDarkMode } = useContext(DarkModeContext);
  const [statusModalOpen, setStatusModalOpen] = useState(false);

  const renderIconPopups = () => (
    <div className="product-card-icons">
      {product.product.tags?.map((tag, index) => (
        <Popup
          key={index}
          trigger={
            tag.img ? (
              <img src={tag.img} className="product-icon" alt="icon" />
            ) : (
              <Icon
                className="product-icon"
                name={tag.icon || "tag"}
                style={{ color: tag.color || "#fdde81" }}
                size="large"
              />
            )
          }
          content={tag.description}
        />
      ))}
    </div>
  );
  const renderLabelTags = () => (
    <LabelGroup className="product-card-labels">
      {product.product.label_tags?.map((tag, index) => {
        if (tag.description) {
          return (
            <Popup
              key={index}
              trigger={
                <Label key={index} className={tag.color}>
                  {tag.icon && <Icon name={tag.icon} />}
                  {tag.label}
                </Label>
              }
              content={tag.description}
            />
          );
        }

        return (
          <Label key={index} className={tag.color}>
            {tag.icon && <Icon name={tag.icon} />}
            {tag.label}
          </Label>
        );
      })}
    </LabelGroup>
  );

  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(product.endDate),
  );

  const warranty = calculateDaysDifference(
    product.warrantyDate,
    product.dateCompleted ?? product.endDate,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(product.endDate));
    }, 60000); // Update every minute

    return () => {
      clearInterval(interval);
    };
  }, [product.endDate]);

  return (
    <>
      <Segment.Group
        className={`product-card ${isDarkMode ? "dark-mode" : ""}`}
      >
        <Segment
          basic
          inverted={isDarkMode}
          className="product-card-image-wrapper "
        >
          <div className="product-card-icons-wrapper">
            {renderLabelTags()}
            {!isTradeCard && renderIconPopups()}
          </div>
          <div
            className="product-card-image"
            style={{
              backgroundImage: `linear-gradient(180deg, var(--gradient) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 80%, var(--gradient) 100%), url(${product.product.thumbnail})`,
            }}
          ></div>
        </Segment>
        <Segment inverted={isDarkMode} className="product-details">
          <div className="product-header">
            <div className="product-description">
              <div>Username: {product.product.username}</div>
              <div>Region: {getRegion(product.product.region)}</div>
              <div>Level: {product.discreteLevel || 30}+</div>{" "}
              {/*no discretelevel in data */}
              <div className="be">
                Blue Essence:
                {product.product.blueEssence <= 0
                  ? " <10,000"
                  : " " + product.product.blueEssence.toLocaleString() + "+"}
                {product.product.isHandleveled ? (
                  <InfoPopup description="This account contains champion capsules." />
                ) : null}
              </div>
              <div>
                Orange Essence: {product.product.orangeEssence.toLocaleString()}
              </div>
              {rankedValues.includes(product.product.rank) && (
                <div>
                  Ranked Wins/Losses: {product.product.soloWins || " - "}/
                  {product.product.soloLosses || " - "}
                </div>
              )}
              <div>
                Minimum Bid Amount:{" "}
                {product.minimumBidAmount
                  ? `€${product.minimumBidAmount}`
                  : "-"}
              </div>
              <div>
                Insta Buy Amount:{" "}
                {product.instaBuyAmount ? `€${product.instaBuyAmount}` : "-"}
              </div>
              {isActive && (
                <div>
                  <Icon name={"clock"} />
                  Ends in: {timeRemaining}
                </div>
              )}
              {!isActive && (
                <div>
                  Completed Date:{" "}
                  {product.dateCompleted
                    ? new Date(product.dateCompleted).toLocaleDateString(
                        "en-US",
                        {
                          hour: "numeric",
                          minute: "numeric",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        },
                      )
                    : "-"}
                </div>
              )}
              <div>
                Warranty:{" "}
                {product.warrantyDate
                  ? `${warranty} ${warranty === 1 ? "day" : "days"}`
                  : "None"}
              </div>
              <div>
                Highest Bid:{" "}
                {product.highestBidAmount
                  ? `€${product.highestBidAmount}`
                  : "-"}
              </div>
              <div>Bids Placed: {product.totalBids ?? "0"}</div>
            </div>
          </div>
        </Segment>
        <Segment className="product-card-buttons" inverted={isDarkMode} basic>
          <ShowSkinsPopup
            skins={product.product.skins}
            permanent_skins={product.product.permanentSkins}
            owned_skins={product.product.ownedSkins}
            relevantSkins={product.product.relevantSkins}
          />
        </Segment>
        <Segment className="product-card-buttons" inverted={isDarkMode} basic>
          <Button
            primary
            onClick={() => {
              setStatusModalOpen(true);
            }}
          >
            View Status
          </Button>
        </Segment>
        <Segment className="product-card-buttons" inverted={isDarkMode} basic>
          <Button
            primary
            onClick={() => {
              setIsEdit(true);
              setSelectedProduct(product);
            }}
          >
            Edit
          </Button>
        </Segment>
        {isActive && (
          <Segment className="product-card-buttons" inverted={isDarkMode} basic>
            <Button
              color="red"
              onClick={() => {
                setIsCancel(true);
                setSelectedProduct(product);
              }}
            >
              Cancel
            </Button>
          </Segment>
        )}
      </Segment.Group>
      {statusModalOpen && (
        <AuctionItemStatusModal
          setOpen={setStatusModalOpen}
          auctionItem={product}
        />
      )}
    </>
  );
}
