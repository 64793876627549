import { useCallback, useEffect, useState } from "react";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import BuyAccountPage from "./components/BuyAccountPage";
import ConfirmEmail from "./components/ConfirmEmail";
import FaqPage from "./components/FaqPage";
import Footer from "./components/Footer";
import ForgotPasswordConfirmPage from "./components/ForgotPasswordConfirmPage";
import ForgotPasswordPage from "./components/ForgotPasswordPage";
import LolAccShopHomePage from "./components/LolAccShopHomePage";
import MyAccountPage from "./components/MyAccountPage";
import MyCartPage from "./components/MyCartPage";
import NavigationBar from "./components/NavigationBar";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";
import PurchaseSuccessful from "./components/PurchaseSuccessful";
import PaymentProcessing from "./components/PaymentProcessing";
import SmurfSkinsHomepage from "./components/SmurfSkinsHomepage";
import TooManyRequestsPage from "./components/TooManyRequestsPage";
import TosPage from "./components/TosPage";
import UserProfile from "./components/UserProfile";
import {
  allowDarkMode,
  discordLink,
  leagueShopMode,
  supportEmail,
} from "./constants";
import CartContext from "./contexts/CartContext";
import DarkModeContext from "./contexts/DarkModeContext";
import PriceContext from "./contexts/PricesContext";
import SkinContext from "./contexts/SkinContext";
import FeatureStatusContext from "./contexts/FeatureStatusContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PurchaseFailed from "./components/PurchaseFailed";
import MarketplacePage from "./pages/Marketplace";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_LIVE_MODE === "1"
    ? process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY
    : process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY,
);

function App() {
  const [skinShards, setSkinShards] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [prices, setPrices] = useState({});
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.hasOwnProperty("isDarkMode")
      ? localStorage.getItem("isDarkMode") === "true"
      : true,
  );
  const [loadingSkins, setLoadingSkins] = useState(true);
  const [loadingCartItems, setLoadingCartItems] = useState(true);
  const [loadingPrices, setLoadingPrices] = useState(true);
  const [isAuctionEnabled, setIsAuctionEnabled] = useState(false);
  const [isParamEnabled, setIsParamEnabled] = useState(false);
  const [isRedsysEnabled, setIsRedsysEnabled] = useState(false);
  const [isTradeEnabled, setIsTradeEnabled] = useState(false);

  const dataTheme = isDarkMode ? "dark" : "light";
  const isSmurfSkins = leagueShopMode === "smurfskins";

  const fetchSkinShards = useCallback(async () => {
    try {
      setLoadingSkins(true);
      const response = await fetch("/api/skins/");
      if (!response.ok) {
        return;
      }
      const json = await response.json();

      setSkinShards(
        json.map((item) => ({
          id: item.id,
          title: item.name,
          description: item.champion,
          lane: item.lanes,
          role: item.roles,
          tier: item.tier,
          value: item.value,
        })),
      );
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoadingSkins(false);
    }
  }, []);

  const refreshCart = useCallback(async () => {
    setLoadingCartItems(true);
    try {
      const response = await fetch("/api/user/cart/");

      if (!response.ok) {
        return;
      }
      const json = await response.json();

      setCartItems(json);
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoadingCartItems(false);
    }
  }, []);

  const fetchPrices = useCallback(async () => {
    setLoadingPrices(true);
    try {
      const response = await fetch("/api/prices/");
      if (!response.ok) {
        return;
      }
      setPrices(await response.json());
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoadingPrices(false);
    }
  }, []);

  const fetchAllFeatureStatus = useCallback(
    async (setTrade, setAuction, setParam, setRedsys) => {
      try {
        const response = await fetch(`/api/feature-status/all/`);
        if (response.ok) {
          const json = await response.json();
          if (json.trade === true) {
            setTrade(true);
          }
          if (json.auction === true) {
            setAuction(true);
          }
          if (json.param === true) {
            setParam(true);
          }
          if (json.redsys === true) {
            setRedsys(true);
          }
          return;
        }
      } catch (reason) {
        console.log(reason);
      }
    },
    [],
  );

  const toggleDarkMode = (mode) => {
    localStorage.setItem("isDarkMode", mode);
    setIsDarkMode(mode);
  };

  useEffect(() => {
    fetchSkinShards();
    fetchPrices();
    refreshCart();
    fetchAllFeatureStatus(
      setIsTradeEnabled,
      setIsAuctionEnabled,
      setIsParamEnabled,
      setIsRedsysEnabled,
    );
  }, [refreshCart, fetchSkinShards, fetchPrices, fetchAllFeatureStatus]);

  return (
    <Router>
      <DarkModeContext.Provider
        value={
          allowDarkMode
            ? { isDarkMode, toggleDarkMode }
            : { isDarkMode: false, toggleDarkMode: () => {} }
        }
      >
        <PriceContext.Provider
          value={{
            prices,
            loadingPrices,
            fetchPrices,
          }}
        >
          <SkinContext.Provider
            value={{
              skinShards,
              loading: loadingSkins,
            }}
          >
            <CartContext.Provider
              value={{
                cartItems,
                loadingCartItems,
                refreshCart,
                setCartItems,
              }}
            >
              <FeatureStatusContext.Provider
                value={{
                  isTradeEnabled,
                  isAuctionEnabled,
                  isParamEnabled,
                  isRedsysEnabled,
                }}
              >
                <Elements stripe={stripePromise}>
                  <div>
                    <NavigationBar
                      allowDarkMode={allowDarkMode}
                      displayLogoTitle={isSmurfSkins}
                      isAuctionEnabled={isAuctionEnabled}
                    />
                  </div>
                  {!isSmurfSkins && (
                    <div
                      style={{
                        padding: "10px 0",
                        textAlign: "center",
                        backgroundColor: "rgb(0 0 0 / 20%)",
                        fontSize: "15px",
                      }}
                    >
                      <p style={{ margin: "2px" }}>
                        Dear customers, Lolaccshop is archived since Feb. 29,
                        2024. Buy/Sell feature is not available in this shop.
                      </p>
                      <p style={{ margin: "2px" }}>
                        For continued service, please visit our shop{" "}
                        <a
                          href="https://smurfskins.lol"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Smurfskins
                        </a>
                        . You can export all your existing data from{" "}
                        <Link to={"/my-account/profile/export-data/"}>
                          here
                        </Link>{" "}
                        and transfer your balance from{" "}
                        <Link
                          to={
                            "/my-account/profile/transfer-balance-cross-site/"
                          }
                        >
                          here
                        </Link>
                        .
                      </p>
                      <p>
                        If you have any questions, please contact us at{" "}
                        <a href={`mailto:${supportEmail}`}>{supportEmail}</a> or
                        reach out to us at{" "}
                        <a href={discordLink} target="_blank" rel="noreferrer">
                          Discord
                        </a>
                        .
                      </p>
                    </div>
                  )}
                  <div className="content-container" data-theme={dataTheme}>
                    <Switch>
                      <Route path="/buy-account/">
                        <BuyAccountPage />
                      </Route>
                      {isAuctionEnabled && (
                        <Route path="/marketplace/">
                          <MarketplacePage />
                        </Route>
                      )}
                      <Route path="/faq/">
                        <FaqPage />
                      </Route>
                      <Route path="/my-account/profile/">
                        <UserProfile />
                      </Route>
                      <Route path="/my-account/confirm-email/:key/">
                        <ConfirmEmail />
                      </Route>
                      <Route path="/my-account/forgot-password/confirm/:uid/:token/">
                        <ForgotPasswordConfirmPage />
                      </Route>
                      <Route path="/my-account/forgot-password/">
                        <ForgotPasswordPage />
                      </Route>
                      <Route path="/my-account/">
                        <MyAccountPage />
                      </Route>
                      <Route path="/tos/">
                        <TosPage />
                      </Route>
                      <Route path="/privacy-policy/">
                        <PrivacyPolicyPage />
                      </Route>
                      <Route path="/my-cart/purchase-completed/">
                        <PurchaseSuccessful />
                      </Route>
                      <Route path="/my-cart/payment-processing/">
                        <PaymentProcessing />
                      </Route>
                      <Route path="/my-cart/purchase-failed/">
                        <PurchaseFailed />
                      </Route>
                      <Route path="/my-cart/">
                        <MyCartPage />
                      </Route>
                      <Route path="/429/">
                        <TooManyRequestsPage />
                      </Route>
                      <Route path="/">
                        {isSmurfSkins ? (
                          <SmurfSkinsHomepage />
                        ) : (
                          <LolAccShopHomePage />
                        )}
                      </Route>
                    </Switch>
                  </div>
                  <div>
                    <Footer />
                  </div>
                </Elements>
              </FeatureStatusContext.Provider>
            </CartContext.Provider>
          </SkinContext.Provider>
        </PriceContext.Provider>
      </DarkModeContext.Provider>
    </Router>
  );
}

export default App;
