import React, { useCallback, useContext, useEffect, useState } from "react";
import ReferAuctionTinyProductCard from "./ReferAuctionTinyProductCard";
import { Header, Icon, Loader } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";
import { Link } from "react-router-dom";
import SkinContext from "../contexts/SkinContext";
import {
  getIsOldStock,
  getLastPlayedDateObject,
  getProductTags,
  getThumbnail,
  rankToValue,
  divisionMap,
} from "../utils";
import PriceContext from "../contexts/PricesContext";

export default function NoResultsShowMarketplace({
  filter,
  auctions,
  loading,
}) {
  const { isDarkMode } = useContext(DarkModeContext);
  const [filteredAuctions, setFilteredAuctions] = useState([]);
  const { skinShards } = useContext(SkinContext);
  const { prices } = useContext(PriceContext);

  const showResults = useCallback(
    (auctions, skinShards, filter) => {
      let output = [...auctions];
      // selected skins, champions, lanes, roles skins
      // to obtain product thumbnail
      let interestedSkins = [];
      // get all mythic skins list from skinShards
      // to show mythic tag
      const mythicSkins = skinShards.reduce((filtered, skin) => {
        if (skin.tier === "MYTHIC") filtered.push(skin.id);
        return filtered;
      }, []);
      // filter by premium
      if (filter.premium) {
        output = output.filter((o) => o.product.isPremium);
      }
      // filter account types: unranked, handleveled, ranked
      const accountType = filter.accountType || "any";
      if (accountType === "unranked") {
        output = output.filter(
          (o) =>
            !o.product.isHandleveled &&
            o.product.rank?.toLowerCase() === accountType,
        );
      } else if (accountType === "handleveled") {
        output = output.filter((o) => o.product.isHandleveled);
      } else if (accountType === "ranked") {
        if (filter.rank) {
          output = output.filter((o) => {
            return (
              o.product.rank?.substring(0, 2) === filter.rank &&
              (filter.division
                ? divisionMap(o.product.division) === parseInt(filter.division)
                : true)
            );
          });
        } else {
          output = output.filter((o) => {
            return (
              !o.product.isHandleveled &&
              o.product.rank?.toLowerCase() !== "unranked"
            );
          });
        }
      }
      if (filter.flashKey) {
        output = output.filter((o) => o.product.flashKey === filter.flashKey);
      }
      if (filter.region && filter.region.length > 0)
        output = output.filter((o) => filter.region.includes(o.product.region));
      if (filter.blueEssence)
        output = output.filter(
          (o) => o.product.blueEssence >= filter.blueEssence,
        );
      if (filter.oeStart || filter.oeEnd)
        output = output.filter((o) => {
          let isFiltered = true;
          if (filter.oeStart)
            isFiltered =
              isFiltered && o.product.orangeEssence >= filter.oeStart;
          if (filter.oeEnd)
            isFiltered = isFiltered && o.product.orangeEssence <= filter.oeEnd;
          return isFiltered;
        });
      if (filter.skinShard && filter.skinShard.length > 0) {
        interestedSkins = interestedSkins.concat(
          filter.skinShard.map((skin) => parseInt(skin)),
        );
        output = output.filter((o) => {
          let allSkins = o.product.skins.concat(
            o.product.ownedSkins,
            o.product.permanentSkins,
          );
          return filter.skinShard.every((skin) =>
            allSkins.includes(parseInt(skin)),
          );
        });
      }
      if (filter.champion && filter.champion.length > 0) {
        const championSkinIds = skinShards
          .filter((s) => filter.champion.includes(s.description))
          .map((s) => s.id);
        interestedSkins = [...interestedSkins, ...championSkinIds];
        output = output.filter((o) => {
          const skins = o.product.skins.concat(
            o.product.ownedSkins,
            o.product.permanentSkins,
          );
          const count = skins.filter((skin) =>
            championSkinIds.includes(skin),
          ).length;
          return count >= 1;
        });
        output.sort((a, b) => {
          const skinsA = a.product.skins.concat(
            a.product.ownedSkins,
            a.product.permanentSkins,
          );
          const skinsB = b.product.skins.concat(
            b.product.ownedSkins,
            b.product.permanentSkins,
          );
          const matchedSkinsA = skinsA.filter((v) =>
            championSkinIds.includes(v),
          ).length;
          const matchedSkinsB = skinsB.filter((v) =>
            championSkinIds.includes(v),
          ).length;
          return matchedSkinsB - matchedSkinsA;
        });
      }
      if (filter.lane) {
        const laneSkinIds = skinShards
          .filter((s) =>
            filter.lane.toLowerCase().includes(s.lane?.toLowerCase()),
          )
          .map((s) => s.id);
        interestedSkins = [...interestedSkins, ...laneSkinIds];
        output =
          output.length > 0
            ? output.reduce((filtered, o) => {
                const skins = o.product.skins.concat(
                  o.product.ownedSkins,
                  o.product.permanentSkins,
                );
                let tempSkins = skins.filter((skin) =>
                  laneSkinIds.includes(skin),
                );
                if (tempSkins.length > 0) {
                  o["laneSkinCount"] = tempSkins.length;
                  filtered.push(o);
                }
                return filtered;
              }, [])
            : output;

        output.sort((a, b) => b.laneSkinCount - a.laneSkinCount);
      }
      if (filter.rankOrder) {
        if (filter.rankOrder === "LowToHigh") {
          output.sort((a, b) => {
            const rankA =
              (a.product.rank ? a.product.rank.substring(0, 2) : "") +
              divisionMap(a.product.division);
            const rankB =
              (b.product.rank ? b.product.rank.substring(0, 2) : "") +
              divisionMap(b.product.division);
            const valueA = rankToValue(rankA);
            const valueB = rankToValue(rankB);
            if (valueA < valueB) {
              return 1;
            } else if (valueA > valueB) {
              return -1;
            }
            return 0;
          });
        }

        if (filter.rankOrder === "HighToLow") {
          output.sort((a, b) => {
            const rankA =
              (a.product.rank ? a.product.rank.substring(0, 2) : "") +
              divisionMap(a.product.division);
            const rankB =
              (b.product.rank ? b.product.rank.substring(0, 2) : "") +
              divisionMap(b.product.division);
            const valueA = rankToValue(rankA);
            const valueB = rankToValue(rankB);
            if (valueA < valueB) {
              return -1;
            } else if (valueA > valueB) {
              return 1;
            }
            return 0;
          });
        }
      }

      if (filter.role) {
        const roleSkinIds = skinShards
          .filter((s) =>
            s.role?.toLowerCase().includes(filter.role.toLowerCase()),
          )
          .map((s) => s.id);
        interestedSkins = [...interestedSkins, ...roleSkinIds];

        output =
          output.length > 0
            ? output.reduce((filtered, o) => {
                const skins = o.product.skins.concat(
                  o.product.ownedSkins,
                  o.product.permanentSkins,
                );
                let tempSkins = skins.filter((skin) =>
                  roleSkinIds.includes(skin),
                );
                if (tempSkins.length > 0) {
                  o["roleSkinCount"] = tempSkins.length;
                  filtered.push(o);
                }
                return filtered;
              }, [])
            : output;
        output.sort((a, b) => b.roleSkinCount - a.roleSkinCount);
      }
      if (filter.accountTier) {
        const accountSkinIds = skinShards
          .filter((s) => s.tier === filter.accountTier)
          .map((s) => s.id);
        interestedSkins = [...interestedSkins, ...accountSkinIds];
        output =
          output.length > 0
            ? output.reduce((filtered, o) => {
                const skins = o.product.skins.concat(
                  o.product.ownedSkins,
                  o.product.permanentSkins,
                );
                const tempSkins = skins.filter((skin) =>
                  accountSkinIds.includes(skin),
                );
                if (tempSkins.length > 0) {
                  o["accountSkinCount"] = tempSkins.length;
                  filtered.push(o);
                }
                return filtered;
              }, [])
            : output;
      }

      //Slice 20 output and add thumbnail and tags
      output = output.slice(0, 20).map((o) => {
        // important to convert empty string to null
        // because it raises errors when sending data to drf
        // when creating cart item
        if (o.product.soloWins === "") o.product.soloWins = null;
        if (o.product.soloLosses === "") o.product.soloLosses = null;

        const productSkins = [
          ...o.product.skins,
          ...o.product.permanentSkins,
          ...o.product.ownedSkins,
        ];
        const mythicCount = productSkins.filter((skin) =>
          mythicSkins.includes(skin),
        ).length;

        o.product["isOldStock"] = getIsOldStock(
          getLastPlayedDateObject(o.product.dateLastPlayed?.slice(0, 10)),
          prices,
        );
        // mark to show warning that it's auctioned item
        o.product["isAuction"] = true;
        const [tags, label_tags] = getProductTags(
          o.product,
          filter,
          mythicCount,
          prices,
        );
        o.product["tags"] = tags;
        o.product["label_tags"] = label_tags;

        const thumbnailSkins =
          interestedSkins.length > 0
            ? productSkins.filter((skin) => interestedSkins.includes(skin))
            : productSkins;

        const [thumbnail, thumbnailSkin] = getThumbnail(
          skinShards,
          thumbnailSkins,
        );
        o.product["thumbnail"] = thumbnail;
        o.product["relevantSkins"] = [...interestedSkins, ...[thumbnailSkin]];
        return o;
      });
      setFilteredAuctions(output);
    },
    [prices],
  );

  useEffect(() => {
    showResults(auctions, skinShards, filter);
  }, [auctions, skinShards, filter, showResults]);

  const renderProducts = () => (
    <div className="product-card-group tiny">
      {filteredAuctions.map((auction, index) => (
        <div
          style={{ display: "flex", alignSelf: "stretch" }}
          key={auction.product.id}
          data-aos="fade-up"
          data-aos-duration={500}
        >
          <ReferAuctionTinyProductCard auction={auction} />
        </div>
      ))}
    </div>
  );

  return (
    <div className="no-search-results">
      <Header inverted={isDarkMode} icon>
        <Icon name="search" />
        No search results found.
      </Header>
      {loading ? (
        <>
          <Header inverted={isDarkMode} icon>
            You can explore more accounts on the
            <Link to="/marketplace"> Marketplace</Link>.
            <br />
            <br />
            Fetching similar marketplace accounts{" "}
            <Loader active inline size="small" inverted={isDarkMode}></Loader>
          </Header>
        </>
      ) : filteredAuctions.length > 0 ? (
        <>
          <Header inverted={isDarkMode} icon>
            Here are some results from
            <Link to="/marketplace"> Marketplace</Link> that may interest you:
          </Header>
          {renderProducts()}
        </>
      ) : null}
    </div>
  );
}
