import { rankedValues, skinThumbnailLink, useSkinThumbail } from "./constants";
const ranks = require("./json/ranks.json");

const getFilter = (query) => ({
  region: query.getAll("region"),
  blueEssence: query.get("blue-essence-start"),
  skinShard: query.getAll("skin-shard"),
  champion: query.getAll("champion"),
  accountType: query.get("account-type"),
  accountTier: query.get("account-tier"),
  premium: query.get("premium"),
  highDemand: query.get("high-demand") || "false",
  veryHighDemand: query.get("very-high-demand") || "false",
  rank: query.get("rank"),
  division: query.get("division"),
  flashKey: query.get("flash-key"),
  role: query.get("role"),
  lane: query.get("lane"),
  oeStart: query.get("orange-essence-start"),
  oeEnd: query.get("orange-essence-end"),
  rankOrder: query.get("rank-order"),
  accountAge: query.get("account-age"),
  productId: query.get("pid"),
});

const rankingOrder = {
  ch: 1,
  ch1: 1,
  ch2: 1,
  ch3: 1,
  ch4: 1,
  gr: 2,
  gr1: 2,
  gr2: 2,
  gr3: 2,
  gr4: 2,
  ma: 3,
  ma1: 3,
  ma2: 3,
  ma3: 3,
  ma4: 3,
  di1: 4,
  di2: 5,
  di3: 6,
  di4: 7,
  em1: 8,
  em2: 9,
  em3: 10,
  em4: 11,
  pl1: 12,
  pl2: 13,
  pl3: 14,
  pl4: 15,
  go1: 16,
  go2: 17,
  go3: 18,
  go4: 19,
  si1: 20,
  si2: 21,
  si3: 22,
  si4: 23,
  si5: 24,
  br1: 25,
  br2: 26,
  br3: 27,
  br4: 28,
  ir1: 29,
  ir2: 30,
  ir3: 31,
  ir4: 32,
  un: 33,
};

function rankToValue(rank) {
  if (typeof rank === "string") {
    const lowercaseRank = rank.toLowerCase();
    if (rankingOrder.hasOwnProperty(lowercaseRank)) {
      return rankingOrder[lowercaseRank] || 14;
    }
  }
  // use meadian value as default
  return 14;
}

const divisionMap = (val) => {
  if (val === "I") return 1;
  if (val === "II") return 2;
  if (val === "III") return 3;
  if (val === "IV") return 4;
  return "";
};

const getDecodedRank = (rank) => {
  if (rank === "0" || rank === "UN") return null;
  return ranks.ranks?.[rank]?.key ?? rank;
};

const getDecodedDivision = (division) => {
  if (division === 0) return null;
  return ranks.divisions?.[division - 1]?.key ?? division;
};

const getMythicExtraPrice = (
  isHandleveled,
  rank,
  isPremium,
  isOldStock,
  prices,
) => {
  if (isHandleveled) return prices.handleveledMythicPrice;
  else if (rank && !["0", "UN"].includes(rank.slice(0, 2)))
    return prices.rankMythicPrice;
  else if (isPremium) return prices.premiumMythicPrice;
  else if (isOldStock) return prices.oldStockMythicPrice;
  else return prices.standardMythicPrice;
};

const getBEBasedPrice = (
  blueEssence,
  basePrice,
  extraBEStart,
  extraBEPrice,
  discount,
) => {
  let price = basePrice;
  if (blueEssence >= extraBEStart) {
    const extraBE = blueEssence - extraBEStart;
    const extraPrice = (Math.floor(extraBE / 10000) + 1) * extraBEPrice;
    price += extraPrice;
  }
  const discountAmount = (price * discount).toFixed(2);
  return price - discountAmount;
};

const getPrice = (
  blueEssence,
  isHandleveled,
  rank,
  division,
  isPremium,
  isOldStock,
  mythicCount,
  isHighDemand,
  isVeryHighDemand,
  prices,
) => {
  let price = 0;
  if (isHandleveled) {
    price += prices.handleveledPrice;
  } else if (rank && !["0", "UN"].includes(rank.slice(0, 2))) {
    // Buy Account Page sends only short rank ie `DI` for DIAMOND. Get full rank using the short rank
    let fullRank = getDecodedRank(rank);
    if (!fullRank) return NaN;
    let fullDivision = getDecodedDivision(division);
    fullRank = fullRank.toLowerCase(); // IRON -> iron
    fullDivision = fullDivision ?? "";
    const priceKey = `${fullRank}${fullDivision}Price`; // ironIIPrice
    price += prices[priceKey];
  } else if (isPremium)
    price += getBEBasedPrice(
      blueEssence,
      prices.premiumBasePrice,
      prices.premiumExtraBePriceStart,
      prices.premiumExtraBePrice,
      0,
    );
  else if (isOldStock)
    price += getBEBasedPrice(
      blueEssence,
      prices.oldStockBasePrice,
      prices.oldStockExtraBePriceStart,
      prices.oldStockExtraBePrice,
      0,
    );
  else
    price += getBEBasedPrice(
      blueEssence,
      prices.standardBasePrice,
      prices.standardExtraBePriceStart,
      prices.standardExtraBePrice,
      0,
    );

  if (mythicCount > 0)
    price += getMythicExtraPrice(
      isHandleveled,
      rank,
      isPremium,
      isOldStock,
      prices,
    );
  else {
    if (isHighDemand) price += prices.highDemandCharge;
    else if (isVeryHighDemand) price += prices.veryHighDemandCharge;
    else {
    }
  }
  return price === 0 ? NaN : price;
};

const getThumbnail = (all_skins, product_skins) => {
  if (useSkinThumbail) {
    var maxValueSkin = null;
    var maxValue = 0;
    all_skins.forEach((skin) => {
      if (product_skins.includes(skin.id) && skin.value > maxValue) {
        maxValueSkin = skin.id;
        maxValue = skin.value;
      }
    });
    if (maxValueSkin) {
      const champion_id = Math.floor(maxValueSkin / 1000);
      return [
        new URL(`${champion_id}/${maxValueSkin}.jpg`, skinThumbnailLink),
        maxValueSkin,
      ];
    }
  }
  return ["/static/images/img-product.png", -1];
};

const regionMapping = {
  LA1: "LAN",
  LA2: "LAS",
  OC1: "OCE",
};

const hasWarranty = (prices, isPremium, isHandleveled, rank, isOldStock) => {
  // only compare first 2 letters of rank
  if (rankedValues.map((r) => r.slice(0, 2)).includes(rank?.slice(0, 2)))
    return prices.rankedAccountWarranty;
  if (isPremium) return prices.premiumAccountWarranty;
  if (isHandleveled) return prices.handleveledAccountWarranty;
  if (isOldStock) return prices.oldStockAccountWarranty;
  return prices.standardAccountWarranty;
};
const getRegion = (region) => {
  return regionMapping[region] || region;
};

const getRankAndDivision = (rank, division) => {
  // returns rank division tex and color
  let decodedRank = getDecodedRank(rank);
  let decodedDivision = getDecodedDivision(division);
  return [
    `${decodedRank} ${decodedDivision ? decodedDivision : ""}`,
    ranks.ranks[rank]?.color ?? decodedRank?.toLowerCase() ?? "black",
  ];
};

const getSkinCount = (skinList) => {
  return skinList.length;
};

const getSkinScore = (skinList, skinShards) => {
  return skinShards
    .filter((skin) => skinList.includes(skin.id))
    .reduce((sum, skin) => sum + skin.value, 0);
};

const getIsHighDemand = (skinList, skinShards) => {
  // currently for accounts with
  // single skin with score >= 100
  // OR
  // total skin score >= 175
  // has at least one skin with score >= 100
  const singleSkinGe100 = skinList.filter((skin) =>
    skinShards.find((s) => s.id === skin && s.value >= 100),
  );
  if (singleSkinGe100.length > 0) return true;
  const skinScore = getSkinScore(skinList, skinShards);
  if (skinScore >= 175) return true;
  return false;
};

const getLabelTags = (
  isHandleveled,
  rank,
  division,
  flashKey,
  isPremium = false,
  hasWarranty = true,
  isOldStock = false,
  isHighDemand = false,
  isVeryHighDemand = false,
  isAramOnly = false,
  isAuction = false,
) => {
  let labelTags = [];
  // Add labeled tag for ranked accounts
  if (rank && !["0", "UN"].includes(rank.slice(0, 2))) {
    let fullRank = getRankAndDivision(rank, division);
    labelTags.push({
      label: fullRank[0],
      icon: "shield",
      color: fullRank[1],
    });
  }

  if (!hasWarranty) {
    labelTags.push({
      label: "No Warranty",
      icon: "warning sign",
      color: "orange",
      description:
        "This account has no warranty and has a high chance of getting banned.",
    });
  }

  // Add labeled tag for premium accounts
  if (isPremium && !isAuction) {
    labelTags.push({
      label: "Premium",
      icon: "star",
      color: "gold",
      description: "This account has significantly lower banrate.",
    });
  }

  //  Add labeled tag for handleveled accounts
  if (isHandleveled) {
    if (isAramOnly) {
      labelTags.push({
        label: "Handleveled (ARAM only)",
        icon: "handshake outline",
        color: "handleveled",
        description:
          "This account has been leveled by humans exclusively on ARAM mode.",
      });
    } else {
      labelTags.push({
        label: "Handleveled",
        icon: "handshake outline",
        color: "handleveled",
        description:
          "This account has been leveled by humans on any game mode, including COOP vs IA.",
      });
    }
  }

  // Hide flash key for unranked accounts
  if (
    (flashKey === "d" || flashKey === "f") &&
    rank &&
    ["0", "UN"].includes(rank.slice(0, 2))
  ) {
    labelTags.push({
      label: `Flash on ${flashKey.toUpperCase()}`,
      color: "handleveled",
    });
  }

  // Add old stock tag for old accounts
  if (isOldStock && !isAuction) {
    labelTags.push({
      label: "Old Stock",
      icon: "info circle",
      color: "blue",
      description:
        "This account was last played in 2022 and has significantly lower banrate and lifetime warranty.",
    });
  }

  // Show safe tag for new safe stock only
  if (
    hasWarranty &&
    !isAuction &&
    !isOldStock &&
    !isPremium &&
    !isHandleveled &&
    (!rank || rank.slice(0, 2) === "UN")
  ) {
    labelTags.push({
      label: "Safe",
      icon: "shield",
      color: "teal",
      description: "This account has very low banrate and lifetime warranty.",
    });
  }

  // Add high demand tag
  if (isHighDemand) {
    labelTags.push({
      label: "High Sales",
      icon: "fire",
      color: "yellow",
      description:
        "This account contains highly demanded skin(s) therefore has higher price.",
    });
  } else if (isVeryHighDemand) {
    labelTags.push({
      label: "Top Selling",
      icon: "fire",
      color: "orange",
      description:
        "This account contains very highly demanded skin(s) therefore has higher price.",
    });
  }

  return labelTags;
};

const getPopupTags = (product, filter, mythicCount, prices) => {
  let tags = [];

  if (product.isAuction) {
    let tag = {
      icon: "info circle",
      color: "#55ACEE",
      description: `This is a marketplace account and it's sold by third party user.`,
    };
    tags.push(tag);
  }

  if (filter) {
    if (filter.role) {
      let tag = {
        icon: "tag",
        color: "#55ACEE",
        description: `This account contains ${product.roleSkinCount} ${filter.role} skin shards.`,
      };
      tags.push(tag);
    }
    if (filter.lane) {
      let tag = {
        icon: "tag",
        color: "#55ACEE",
        description: `This account contains ${product.laneSkinCount} ${filter.lane} skin shards.`,
      };
      tags.push(tag);
    }
  }

  // Add tag for mythic accounts
  if (mythicCount > 0 && !product.isAuction) {
    const mythicPrice = getMythicExtraPrice(
      product.isHandleveled,
      product.rank,
      product.isPremium,
      product.isOldStock,
      prices,
    );
    let tag = {
      icon: "star",
      description: `This account contains ${mythicCount} mythic skin shard(s). ${
        mythicPrice
          ? `Extra ${parseFloat(mythicPrice).toLocaleString(undefined, {
              style: "currency",
              currency: "EUR",
            })} has been applied.`
          : ""
      } `,
    };
    tags.push(tag);
  }
  return tags;
};

const getProductTags = (product, filter, mythicCount, prices) => {
  const tags = getPopupTags(product, filter, mythicCount, prices);
  const labelTags = getLabelTags(
    product.isHandleveled,
    product.rank,
    product.division,
    product.flashKey,
    product.isPremium,
    hasWarranty(
      prices,
      product.isPremium,
      product.isHandleveled,
      product.rank,
      product.isOldStock,
    ),
    product.isOldStock,
    product.isHighDemand,
    product.isVeryHighDemand,
    product.isAramOnly,
    product.isAuction,
  );

  return [tags, labelTags];
};

const getCartItemTags = (product, mythicCount, prices) => {
  const tags = getPopupTags(product, null, mythicCount, prices);
  const labelTags = getLabelTags(
    product.isHandleveled,
    product.rank,
    product.division,
    product.flashKey,
    product.isPremium,
    hasWarranty(
      prices,
      product.isPremium,
      product.isHandleveled,
      product.rank,
      product.isOldStock,
    ),
    product.isOldStock,
    product.isHighDemand,
    product.isVeryHighDemand,
  );

  return [tags, labelTags];
};

const getTradeLabelTags = (product) => {
  const labelTags = getLabelTags(
    product.isHandleveled,
    product.rank,
    product.division,
    product.flashKey,
    product.isBareMetal,
  );

  return [labelTags];
};

const getIsOldStock = (dateLastPLayed, prices) => {
  if (!prices.oldStockMaxDate) {
    return;
  }
  const [year, month, day] = prices.oldStockMaxDate.split("-").map(Number);
  const oldStockMaxDate = new Date(Date.UTC(year, month - 1, day));
  return dateLastPLayed && dateLastPLayed <= oldStockMaxDate;
};

// Utility function to return a Date object from the date string from catalog.
const getLastPlayedDateObject = (dateLastPLayed) => {
  if (!dateLastPLayed) return null;

  let year, month, day;

  // Support for old date values in catalog.
  // Older catalog contains year only.
  // TODO: Remove after full migration of catalog.
  if (typeof dateLastPLayed === "number") {
    year = dateLastPLayed;
    month = 0;
    day = 1;
  } else {
    [year, month, day] = dateLastPLayed.split("-").map(Number);
    month = month - 1;
  }

  return new Date(Date.UTC(year, month, day));
};

const getTomorrowDate = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const formattedTomorrow = `${tomorrow.getFullYear()}-${String(
    tomorrow.getMonth() + 1,
  ).padStart(2, "0")}-${String(tomorrow.getDate()).padStart(2, "0")}`;
  return formattedTomorrow;
};

const calculateDaysDifference = (date1, date2) => {
  const timeDifference = new Date(date1) - new Date(date2);
  const daysDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
};

const calculateTimeRemaining = (endDate) => {
  const currentTime = new Date();
  const remainingTime = new Date(endDate) - currentTime;

  if (remainingTime <= 0) {
    return "0d 0h 0m";
  }

  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));

  return `${days}d ${hours}h ${minutes}m`;
};

export {
  calculateDaysDifference,
  calculateTimeRemaining,
  getCartItemTags,
  getDecodedDivision,
  getDecodedRank,
  getFilter,
  getIsOldStock,
  getLastPlayedDateObject,
  getMythicExtraPrice,
  getPrice,
  getProductTags,
  getRankAndDivision,
  getSkinCount,
  getSkinScore,
  getIsHighDemand,
  getRegion,
  getThumbnail,
  getTomorrowDate,
  getTradeLabelTags,
  rankToValue,
  divisionMap,
};
