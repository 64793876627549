import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { Button, Icon, Popup } from "semantic-ui-react";
import CartContext from "../contexts/CartContext";
import { getDecodedDivision, getDecodedRank } from "../utils";

export default function AddToCartPopup({ product }) {
  const [loading, setLoading] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { cartItems, refreshCart } = useContext(CartContext);

  const addToCart = async () => {
    setLoading(true);
    const body = {
      product_id: product.id,
      region: product.region,
      discrete_blue_essence: product.discreteBlueEssence,
      orange_essence: product.orangeEssence,
      mythic_essence: 0,
      skins: product.skins,
      permanent_skins: product.permanentSkins,
      owned_skins: product.ownedSkins,
      discrete_level: product.discreteLevel,
      is_handleveled: product.isHandleveled,
      is_premium: product.isPremium,
      is_high_demand: product.isHighDemand,
      is_very_high_demand: product.isVeryHighDemand,
      rank: getDecodedRank(product.rank),
      division: getDecodedDivision(product.division),
      flashKey: product.flashKey,
      solo_wins: product.soloWins,
      solo_losses: product.soloLosses,
      date_last_played: product.dateLastPlayed,
    };

    try {
      const response = await fetch("/api/user/cart/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify(body),
      });
      if (response.status === 403) {
        setForbidden(true);
        return;
      }
      if (response.status === 400) {
        const json = await response.json();
        if (json.detail) {
          alert(json.detail);
          return;
        }
      }
      if (!response.ok) {
        alert(
          `Some error occured when adding item to cart with status code: ${response.status}.`,
        );
        setLoading(false);
        return;
      }
      if (response.status === 201) {
        setDisabled(true);
        refreshCart();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getProductAlreadyOnCart = () => {
    return cartItems.some((item) => Number(item.productId) === product.id);
  };

  return (
    <Popup
      on="click"
      open={forbidden}
      trigger={
        <Button
          disabled={getProductAlreadyOnCart() || disabled || loading}
          loading={loading}
          color="twitter"
          onClick={addToCart}
        >
          <Icon name={getProductAlreadyOnCart() ? "check circle" : "cart"} />
          Add to cart
        </Button>
      }
      onClose={() => setForbidden(false)}
    >
      <Popup.Content>
        <p>Login to add items to cart.</p>
        <Button.Group>
          <Button
            color="teal"
            onClick={() => {
              window.open("/my-account/");
              setForbidden(false);
            }}
          >
            Login
          </Button>
          <Button.Or />
          <Button
            onClick={() => {
              window.open("/my-account/");
              setForbidden(false);
            }}
          >
            Register
          </Button>
        </Button.Group>
      </Popup.Content>
    </Popup>
  );
}
