import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { Button, Form, Icon, Input, Modal } from "semantic-ui-react";
import DarkModeContext from "../../contexts/DarkModeContext";
import Error from "../Errors";
import ValidationError from "../Errors/ValidationError";

export const MarketplaceEditModal = ({ setOpen, product, refreshTable }) => {
  const { isDarkMode } = useContext(DarkModeContext);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // const [editDetail, setEditDetail] = useState({
  //   minimumBidAmount: product.minimumBidAmount,
  //   instaBuyAmount: product.instaBuyAmount,
  //   endDate: new Date(product.endDate).toISOString().slice(0, 10),
  // });
  const [editDetail, setEditDetail] = useState({
    instaBuyAmount: product.instaBuyAmount || 0,
  });
  const [editError, setEditError] = useState({
    instaBuyAmount: "",
  });
  const handleModalClose = () => {
    setOpen(false);
    success && refreshTable();
  };

  const handleEdit = async () => {
    const valid = isFormValid();

    if (valid) {
      try {
        setSuccess(false);
        setErrors({});
        setLoading(true);
        const response = await fetch(
          `/api/marketplace/products/${product.product.username}/`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
            body: JSON.stringify({
              instaBuyAmount: Number(editDetail.instaBuyAmount),
            }),
          },
        );
        const data = await response.json();
        if (response.ok) {
          setSuccess(true);
          setErrors({});
        } else {
          setErrors(data);
        }
      } catch (reason) {
        setErrors({
          error: "Error connecting to server. Please try again later.",
        });
        console.log(reason);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEditChange = (e, { name, value }) => {
    setEditDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setEditError((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const isFormValid = () => {
    setEditError({
      instaBuyAmount: "",
    });
    let isValid = true;

    if (
      product.highestBidAmount &&
      editDetail.instaBuyAmount <= Number(product.highestBidAmount)
    ) {
      setEditError({
        instaBuyAmount: "Amount should be greater than highest bid",
      });
      isValid = false;
    } else if (
      product.minimumBidAmount &&
      editDetail.instaBuyAmount <= Number(product.minimumBidAmount)
    ) {
      setEditError({
        instaBuyAmount: "Amount should be greater than minimum bid",
      });
      isValid = false;
    } else if (
      Number(editDetail.instaBuyAmount) === Number(product.instaBuyAmount)
    ) {
      setEditError({
        instaBuyAmount: "Change amount to edit",
      });
      isValid = false;
    }
    return isValid;
  };

  return (
    <Modal
      open
      closeOnDimmerClick={false}
      className={`trade-modal${isDarkMode ? " dark" : ""}`}
      data-theme={isDarkMode ? "dark" : "light"}
      closeIcon
      onClose={handleModalClose}
      onOpen={() => setOpen(true)}
      size={"mini"}
    >
      <Modal.Header>Edit Marketplace Product Detail</Modal.Header>
      <Modal.Content>
        <div>
          <Form inverted={isDarkMode}>
            <Form.Field style={{ width: "100%" }}>
              <label>Edit Insta Buy Amount</label>
              <ValidationError error={editError.instaBuyAmount} />
              <Input
                fluid
                placeholder={"Insta Buy Amount"}
                name="instaBuyAmount"
                type={"number"}
                onChange={handleEditChange}
                value={editDetail.instaBuyAmount}
                inverted={!isDarkMode}
              />
            </Form.Field>
          </Form>
          <Error errors={errors} />
        </div>
      </Modal.Content>

      <Modal.Actions className="trade-modal-actions">
        {!success ? (
          <>
            <Button
              color="twitter"
              onClick={handleEdit}
              disabled={loading}
              loading={loading}
            >
              Edit
            </Button>
            <Button color="red" onClick={handleModalClose} disabled={loading}>
              Close
            </Button>
          </>
        ) : (
          <Button
            color="twitter"
            onClick={handleModalClose}
            icon
            labelPosition="right"
          >
            Done
            <Icon name="check" />
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};
