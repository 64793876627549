import Cookies from "js-cookie";
import { useStripe } from "@stripe/react-stripe-js";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { Button, Form, Icon, Message } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";
import { ParamLoadBalanceModal } from "./ParamLoadBalanceModal";
import FeatureStatusContext from "../contexts/FeatureStatusContext";

const errorMessage =
  "Some error occured during balance load. Please try again later.";

export default function LoadBalance({ title }) {
  const { isRedsysEnabled } = useContext(FeatureStatusContext);
  const gatewayOptions = [
    {
      key: "1",
      text: (
        <span>
          <Icon name="stripe s" /> Stripe
        </span>
      ),
      value: "stripe",
    },
    ...(isRedsysEnabled
      ? [
          {
            key: "2",
            text: (
              <span>
                <Icon name="credit card" /> Credit Card
              </span>
            ),
            value: "card2",
          },
        ]
      : []),
  ];
  const [selectedGateway, setSelectedGateway] = useState(
    isRedsysEnabled ? "card2" : "stripe",
  );
  const history = useHistory();
  const success = history.location.pathname.endsWith("success/");
  const processing = history.location.pathname.endsWith("processing/");
  const [paramModalOpen, setParamModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(null);
  const [loadError, setLoadError] = useState(null);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();

  const handleLoadBalance = async () => {
    try {
      setLoading(true);
      const response = await fetch(`/api/load-balance/${selectedGateway}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({ amount }),
      });
      if (response.ok) {
        setAmount("");
        setAmountError(null);
        setLoadError(null);
        const json = await response.json();
        if (selectedGateway === "card2") {
          const form = document.createElement("form");
          form.method = "POST";
          form.action = json.redsysUrl;
          form.target = "_blank";
          Object.keys(json.redsysPayload).forEach((key) => {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = json.redsysPayload[key];
            form.appendChild(input);
          });
          document.body.appendChild(form);
          form.submit();
          document.body.removeChild(form);
          return;
        } else {
          const error = await stripe.redirectToCheckout({ sessionId: json.id });
          console.log(error);
        }
      } else if (response.status === 400) {
        const json = await response.json();
        setLoadError(json.detail);
      } else {
        setLoadError(errorMessage);
      }
    } catch (reason) {
      console.log(reason);
      setLoadError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    setLoadError(null);
    if (amount === "" || amount === null) {
      setAmountError("This field may not be blank.");
      return;
    }
    if (isNaN(amount)) {
      setAmountError("Please enter a valid number.");
      return;
    }
    if (amount < 2.5) {
      setAmountError("Minimum load amount is €2.5");
      return;
    }
    if (amount > 1000) {
      setAmountError("Maximum load amount is €1000");
      return;
    }
    if (amount.toString().split(".")[1]?.length > 2) {
      setAmountError("Please enter amount upto 2 decimal places.");
      return;
    }
    if (selectedGateway === "card") setParamModalOpen(true);
    else handleLoadBalance();
  };

  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <>
      <div className="load-balance-form attatched-from">
        <Message
          attached
          color={isDarkMode ? "black" : "grey"}
          header={title || "Load Balance"}
          content="Enter the amount you want to load to your in-shop balance."
        />
        <Form
          inverted={isDarkMode}
          className="attached segment"
          success={success}
          error={loadError !== null}
        >
          <Message error content={loadError} />
          <Form.Group widths="equal">
            <Form.Input
              disabled={loading}
              width="3"
              placeholder="Enter amount (EUR)"
              type="text"
              onChange={(e, { value }) => {
                setAmount(value);
                setAmountError(null);
              }}
              value={amount}
              error={amountError}
            />
            <Form.Dropdown
              disabled={loading}
              width="1"
              fluid
              placeholder="Payment method"
              selection
              value={selectedGateway}
              onChange={(e, { value }) => setSelectedGateway(value)}
              options={gatewayOptions}
            />
          </Form.Group>
          <Message
            success
            header="Balance Loaded Succesfully"
            content="The amount has been loaded to your account successfully."
          />
          {processing && (
            <Message
              info
              header="Payment is being processed."
              content="The balance will be loaded to your account after the payment is successful."
            />
          )}
          <Button
            color="twitter"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading}
          >
            Submit
          </Button>
        </Form>
      </div>
      {paramModalOpen && (
        <ParamLoadBalanceModal
          open={paramModalOpen}
          setOpen={setParamModalOpen}
          amount={amount}
        />
      )}
    </>
  );
}
