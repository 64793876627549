import React, { useContext } from "react";
import {
  Button,
  Icon,
  Label,
  LabelGroup,
  Popup,
  Segment,
} from "semantic-ui-react";
import InfoPopup from "./InfoPopup";
import ShowSkinsPopup from "./ShowSkinsPopup";
import { rankedValues } from "../constants";
import DarkModeContext from "../contexts/DarkModeContext";
import { getRegion, calculateDaysDifference } from "../utils";

export default function ReferAuctionTinyProductCard({ auction }) {
  const { isDarkMode } = useContext(DarkModeContext);
  // if bid exists, use highest bid + .01
  // if min bid exists, use min-bid amt
  // if only insta-buy, use instabuy amt
  const bidAmount = auction.highestBidAmount
    ? (auction.highestBidAmount + 0.01).toFixed(2)
    : auction.minimumBidAmount
    ? auction.minimumBidAmount
    : auction.instaBuyAmount;

  const renderIconPopups = () => (
    <div className="product-card-icons">
      {auction.product.tags?.map((tag, index) => (
        <Popup
          key={index}
          trigger={
            tag.img ? (
              <img src={tag.img} className="product-icon" alt="icon" />
            ) : (
              <Icon
                className="product-icon"
                name={tag.icon || "tag"}
                style={{ color: tag.color || "#fdde81" }}
                size="large"
              />
            )
          }
          content={tag.description}
        />
      ))}
    </div>
  );
  const renderLabelTags = () => (
    <LabelGroup className="product-card-labels">
      {auction.product.label_tags?.map((tag, index) => {
        if (tag.description) {
          return (
            <Popup
              key={index}
              trigger={
                <Label key={index} className={tag.color}>
                  {tag.icon && <Icon name={tag.icon} />}
                  {tag.label}
                </Label>
              }
              content={tag.description}
            />
          );
        }

        return (
          <Label key={index} className={tag.color}>
            {tag.icon && <Icon name={tag.icon} />}
            {tag.label}
          </Label>
        );
      })}
    </LabelGroup>
  );

  const warranty = calculateDaysDifference(
    auction.warrantyDate,
    auction.endDate,
  );

  return (
    <Segment.Group className={`product-card ${isDarkMode ? "dark-mode" : ""}`}>
      <Segment
        basic
        inverted={isDarkMode}
        className="product-card-image-wrapper "
      >
        <div className="product-card-icons-wrapper">
          {renderLabelTags()}
          {renderIconPopups()}
        </div>
        <div
          className="tiny product-card-image"
          style={{
            backgroundImage: `linear-gradient(180deg, var(--gradient) 0%, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0) 85%, var(--gradient) 100%), url(${auction.product.thumbnail})`,
          }}
        ></div>
      </Segment>
      <Segment inverted={isDarkMode} className="product-details">
        <div className="product-header">
          <div className="product-description">
            <div>Region: {getRegion(auction.product.region)}</div>
            <div>Level: {auction.discreteLevel || 30}+</div>{" "}
            {/*no discretelevel in data */}
            <div className="be">
              Blue Essence:
              {auction.product.blueEssence <= 0
                ? " <10,000"
                : " " + auction.product.blueEssence.toLocaleString() + "+"}
              {auction.product.isHandleveled ? (
                <InfoPopup description="This account contains champion capsules." />
              ) : null}
            </div>
            <div>
              Orange Essence: {auction.product.orangeEssence.toLocaleString()}
            </div>
            {rankedValues.includes(auction.product.rank) && (
              <div>
                Ranked Wins/Losses: {auction.product.soloWins || " - "}/
                {auction.product.soloLosses || " - "}
              </div>
            )}
            <div>
              Warranty:{" "}
              {auction.warrantyDate
                ? `${warranty} ${warranty === 1 ? "day" : "days"}`
                : "None"}
            </div>
            <div>Bids Placed: {auction.totalBids}</div>
          </div>
          <div className="price">Bid €{bidAmount}</div>
        </div>
      </Segment>
      <Segment className="product-card-buttons" inverted={isDarkMode} basic>
        <ShowSkinsPopup
          skins={auction.product.skins}
          permanent_skins={auction.product.permanentSkins}
          owned_skins={auction.product.ownedSkins}
          relevantSkins={auction.product.relevantSkins}
        />
        <Button
          color="twitter"
          onClick={() => {
            window.open(`/marketplace/?pid=${auction.product.id}`);
          }}
        >
          View Product
        </Button>
      </Segment>
    </Segment.Group>
  );
}
