import Cookies from "js-cookie";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button, Form, Header, Icon, Message } from "semantic-ui-react";
import CartContext from "../../contexts/CartContext";
import DarkModeContext from "../../contexts/DarkModeContext";
import PriceContext from "../../contexts/PricesContext";
import SkinContext from "../../contexts/SkinContext";
import {
  getCartItemTags,
  getIsOldStock,
  getPrice,
  getThumbnail,
} from "../../utils";
import CartItem from "./CartItem";
import CartItemSkeleton from "./CartItemSkeleton";
import Checkout from "./Checkout";
import NoCartItems from "./NoCartItems";
import "./index.css";
const discordLink = process.env.REACT_APP_DISCORD_LINK;

export default function MyCartPage() {
  const [loading, setLoading] = useState(true);
  const [selectedCartItems, setSelectedCartItems] = useState({});
  const [formattedCardItems, setFormattedCartItems] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [preselect, setPreselect] = useState(true);
  const [errorItems, setErrorItems] = useState([]);
  const [allSelected, setAllSelected] = useState(
    Object.keys(selectedCartItems).length === formattedCardItems?.length,
  );

  const history = useHistory();

  const { cartItems, loadingCartItems, refreshCart, setCartItems } =
    useContext(CartContext);
  const { isDarkMode } = useContext(DarkModeContext);
  const { prices, loadingPrices } = useContext(PriceContext);
  const { skinShards } = useContext(SkinContext);

  const deleteCartItems = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/user/cart/", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({ cart_items: Object.keys(selectedCartItems) }),
      });
      if (!response.ok) {
        setLoading(false);
        alert(`Could not delete cart items. Status: ${response.status}`);
        return;
      }
      setCartItems((prev) => {
        const newCartItems = [...prev];
        return newCartItems.filter(
          (cartItem) =>
            !Object.keys(selectedCartItems).includes(cartItem.id.toString()),
        );
      });
      setPreselect(false);
      setSelectedCartItems({});
    } catch (reason) {
      console.log(reason);
    }
  };

  const toggleAllItemsSelected = (e, { checked }) => {
    var temp = {};
    if (checked) {
      formattedCardItems?.forEach((cartItem) => (temp[cartItem.id] = cartItem));
    }
    setSelectedCartItems(temp);
    setAllSelected(!checked);
  };

  const toggleItemSelected = (cartItem, checked) => {
    var temp = { ...selectedCartItems };
    if (checked) {
      temp[cartItem.id] = cartItem;
    } else {
      delete temp[cartItem.id];
    }
    setSelectedCartItems(temp);
  };

  const showCartItems = useCallback(
    (cartItems, skinShards) => {
      let output = [...cartItems];

      const mythicSkins = skinShards.reduce((filtered, skin) => {
        if (skin.tier === "MYTHIC") filtered.push(skin.id);
        return filtered;
      }, []);

      output = output.map((cartItem) => {
        cartItem["isOldStock"] = getIsOldStock(
          cartItem.dateLastPlayed ? new Date(cartItem.dateLastPlayed) : null,
          prices,
        );

        const cartItemSkins = [
          ...cartItem.skins,
          ...cartItem.permanentSkins,
          ...cartItem.ownedSkins,
        ];
        const mythicCount = cartItemSkins.filter((skin) =>
          mythicSkins.includes(skin),
        ).length;

        const [tags, label_tags] = getCartItemTags(
          cartItem,
          mythicCount,
          prices,
        );
        cartItem["tags"] = tags;
        cartItem["label_tags"] = label_tags;

        cartItem["price"] = getPrice(
          cartItem.discreteBlueEssence,
          cartItem.isHandleveled,
          cartItem.rank,
          cartItem.division,
          cartItem.isPremium,
          cartItem.isOldStock,
          mythicCount,
          cartItem["isHighDemand"],
          cartItem["isVeryHighDemand"],
          prices,
        );

        const [thumbnail, thumbnailSkin] = getThumbnail(
          skinShards,
          cartItemSkins,
        );
        cartItem["thumbnail"] = thumbnail;
        cartItem["relevantSkins"] = [thumbnailSkin];

        return cartItem;
      });
      setFormattedCartItems(output);
    },
    [prices],
  );

  useEffect(() => {
    setLoading(true);
    showCartItems(cartItems, skinShards);
    setLoading(false);
  }, [skinShards, prices, cartItems, showCartItems]);

  const renderCart = () =>
    formattedCardItems?.map((cartItem) => (
      <div
        key={cartItem.id}
        className="cart-item"
        style={{
          border: errorItems.includes(Number(cartItem.id))
            ? "1px solid var(--danger)"
            : "1px solid var(--border-color)",
        }}
      >
        <CartItem
          cartItem={cartItem}
          toggleItemSelected={toggleItemSelected}
          selectedCartItems={selectedCartItems}
          setSelectedCartItems={setSelectedCartItems}
          setRefresh={setRefresh}
          setLoading={setLoading}
          setPreselect={setPreselect}
          setCartItems={setCartItems}
        />
      </div>
    ));

  useEffect(() => {
    refreshCart();
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (preselect) {
      toggleAllItemsSelected({}, { checked: true });
    }
  }, [cartItems, preselect]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="cart-page-container">
      <div className="cartpage-header">
        <Header inverted={isDarkMode} as="h1">
          Checkout
          <Header.Subheader>
            You have {formattedCardItems?.length} items in cart.
          </Header.Subheader>
        </Header>

        <Message info className="discount-offer__text">
          <Message.Header>
            Get up to 10% discount by using Account Balance!
          </Message.Header>
          <p>
            Join our <a href={discordLink}>Discord</a> and find out more.
          </p>
        </Message>
      </div>

      <div className="cart-container">
        {!loadingCartItems &&
          !loadingPrices &&
          formattedCardItems?.length === 0 && <NoCartItems />}
        {!loadingCartItems &&
          !loadingPrices &&
          formattedCardItems?.length > 0 && (
            <div className="cart-item-container">
              <Form className="filter-btns">
                <Button
                  onClick={(e) => {
                    toggleAllItemsSelected({}, { checked: allSelected });
                  }}
                >
                  <Icon
                    name={
                      Object.keys(selectedCartItems).length ===
                      formattedCardItems?.length
                        ? "check square outline"
                        : "square outline"
                    }
                  />
                  {Object.keys(selectedCartItems).length ===
                  formattedCardItems?.length
                    ? `Diselect All`
                    : `Select All`}
                </Button>
                <Button
                  onClick={deleteCartItems}
                  disabled={!Object.keys(selectedCartItems).length}
                >
                  <Icon name="trash alternate" />
                  Delete Selected {`(${Object.keys(selectedCartItems).length})`}
                </Button>
                <Button onClick={() => history.push("/buy-account")}>
                  <Icon name="add to cart" />
                  Add More
                </Button>
              </Form>

              <div className="cart-item-group">
                {loading ? (
                  <>
                    <CartItemSkeleton />
                    <CartItemSkeleton />
                    <CartItemSkeleton />
                  </>
                ) : (
                  renderCart()
                )}
              </div>
            </div>
          )}
        <div className="cart-page-sidebar">
          <div className="cart-page-sidebar-row">
            <Checkout
              cartItems={selectedCartItems}
              setErrorItems={setErrorItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
